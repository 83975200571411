import { List, ListItem, ListItemButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { pageDefinitions } from "../pages/pageDefinitions";

export function MainMenu() {
  return (
    <List>
      {Object.values(pageDefinitions).map((page) => (
        <ListItem component={Link} key={page.pageName} disablePadding to={page.pageName}>
          <ListItemButton>
            <Typography variant="overline" color="secondary">
              {page.title}
            </Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
