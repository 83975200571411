import { CssBaseline, createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";
import { Route, Routes } from "react-router-dom";
import { setUpAxios } from "../shared/api/axiosHelper";
import { redirectToLoginSignin } from "../shared/auth/Auth";
import { Licenses } from "../shared/licensing";
import { initAppInsights } from "../shared/logging";
import { lightThemeOptions } from "../shared/theme";
import { handleRedirectUrlState } from "../shared/utilities/handleRedirectUrlState";
import { PortalVariablesTarget, setPortalTarget } from "../shared/variables";
import App from "./App";
import LogoutContainer from "./auth/LogoutContainer";
import IconsPage from "./pages/IconsPage";
import MainPage from "./pages/MainPage";
import { pageDefinitions } from "./pages/pageDefinitions";
import TypographyPage from "./pages/TypographyPage";

LicenseInfo.setLicenseKey(Licenses.MUI_X_PREMIUM_KEY);

const redirected = handleRedirectUrlState();

if (!redirected) {
  initAppInsights({ appId: "Aquarium" });
  setPortalTarget(PortalVariablesTarget.AquariumPortal);
  setUpAxios(redirectToLoginSignin, []);
}

const Entry = () => {
  return (
    <ThemeProvider theme={createTheme(lightThemeOptions)}>
      <CssBaseline />
      <Routes>
        <Route path="logout" element={<LogoutContainer />} />
        <Route element={<App />}>
          <Route index element={<MainPage />} />
          <Route path={pageDefinitions.typography.pageName} element={<TypographyPage />} />
          <Route path={pageDefinitions.icons.pageName} element={<IconsPage />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default Entry;
